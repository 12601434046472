@import "designSystem/colors.scss";

.dashboard-wrapper {
    padding: 40px;
    display: block;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    iframe {
        width: 100%;
        height: 100%;
        border: 1px solid lightgray;
    }

    .blocks{
        display:flex;

        .total-users{
            text-align: center;
            width: 100%;
            background: #fff;
            border-radius: 8px;
            padding-bottom: 5px;
            justify-content: center;
            display: flex;
            align-items: center;
            margin-bottom:30px;
            padding: 20px;

            h1{

            }

            h4{
                color:#787878;
                font-weight: 500;
            }
        }

        .payments{
            padding: 15px 30px;
            background: #fff;
            border-radius: 8px;
            width:78%;
            margin-left:2%;
            max-height: 200px;
            overflow-y: scroll;
            margin-bottom:30px;
            font-size: 0.9rem;

            h3{
                font-size: 0.9rem;
                font-weight: 500;

                span{
                    color:#787878
                }
            }

            table {
                width:100%;

                th{
                    padding: 8px 5px 8px 0px!important;
                    font-size:0.7rem !important;
                }
                td{
                    padding: 8px 5px 8px 0px !important;  
                    font-size:0.7rem !important;
                }
                tr{
                    background-color: #fff !important; 
                }
            }
        }
    }

    .firebase-container{
        background: #fff;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        margin-bottom: 30px;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;

        img{
            width:280px
        }
    }
}
